import React, { useState, useEffect } from "react";
import { Select, Button, Modal, Spin } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Parse from "html-react-parser";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import drophint from "../../assets/images/choose-setting/drophint.png";
import calender from "../../assets/images/choose-setting/calender.svg";
import emailicon from "../../assets/images/choose-setting/emailicon.svg";
import infoblack from "../../assets/images/choose-setting/infoblack.svg";
import IconPrint from "../../assets/images/choose-setting/IconPrint.svg";

import DtlFacebook from "../../assets/images/fb__icon.svg";
import DtlTwitter from "../../assets/images/twitter__icon.svg";
import DtlPintrest from "../../assets/images/pintrest__icon.svg";
import DtlInstagram from "../../assets/images/instragm__icon.svg";

import leftArrow from "../../assets/images/choose-setting/left__arrow.png";
import rightArrow from "../../assets/images/choose-setting/right__arrow.png";

import shareBlue from "../../assets/images/choose-setting/share__blue.png";
import shareFacebook from "../../assets/images/choose-setting/shareIcons_02.png";
import sharePintrest from "../../assets/images/choose-setting/shareIcons_03.png";
import InstaIcon from "../../assets/images/icons/insta__icon.svg";
import FaceBookLike from "../../assets/images/icons/facebook-like.jpg";
import shareTwitter from "../../assets/images/choose-setting/shareIcons_04.png";
import completeYourRing from "../../services/complete-your-ring.service";
import { NotificationManager } from "react-notifications";
import ChooseSettingServices from "../../services/choose-settings.service";
import SelectYourDiamondService from "../../services/select-your-diamond.service";
import colorPanelService from "../../services/color-panel.service";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { setGlobalFontFamily } from "../../actions/PreviewColor/previewColor";
import { useTranslation } from "react-i18next";

const initialDropAHintData = {
	name: "",
	email: "",
	recipientName: "",
	recipientEmail: "",
	giftReason: "",
	giftDeadline: "",
	personalMessage: "",
};
const initialDropAHintDataVal = {
	nameval: "",
	emailval: "",
	recipientNameval: "",
	recipientEmailval: "",
	giftReasonval: "",
	giftDeadlineval: "",
	personalMessageval: "",
};

const initialRequestMoreInfoData = {
	name: "",
	email: "",
	phoneNumber: "",
	contactPreference: "byEmail",
	personalMessage: "",
};

const initialRequestMoreInfoDataVal = {
	nameval: "",
	emailval: "",
	phoneNumberval: "",
	contactPreferenceval: "",
	personalMessageval: "",
};

const initialEmailAFriendInputData = {
	name: "",
	email: "",
	friendName: "",
	friendEmail: "",
	personalMessage: "",
};

const initialEmailAFriendInputDataVal = {
	nameval: "",
	emailval: "",
	friendNameval: "",
	friendEmailval: "",
	personalMessageval: "",
};

const initialScheduleViewingInputData = {
	name: "",
	email: "",
	phoneNumber: "",
	location: "",
	appointmentDate: "",
	appointmentTime: "",
	address: "",
	message: "",
};

const initialScheduleViewingInputDataVal = {
	nameval: "",
	emailVal: "",
	phoneNumberVal: "",
	locationVal: "",
	appointmentDateVal: "",
	appointmentTimeVal: "",
	addressVal: "",
};

export const CompleteYourRing = ({}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const search = useLocation().search;
	const [DealerID, setDealerId] = useState("");
	const paramdealerId = new URLSearchParams(search).get("DealerID");
	const paramdealerID2 = new URLSearchParams(search).get("dealerID1");
	const PairId = new URLSearchParams(search).get("pairId");
	const totalPrice = new URLSearchParams(search).get("totalPrice");
	const paramdealerId1 = new URLSearchParams(search).get("dealerID");
	const gfInventoryID = new URLSearchParams(search).get("gfInventoryID");
	const styleNumber = new URLSearchParams(search).get("styleNumber");
	/* Color Individually Start */

	const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
	const [isLightButtonColor, setIsLightButtonColor] = useState(false);
	const [isLightHoverColor, setIsLightHoverColor] = useState(false);
	const [currencySymbol, setCurrencySymbol] = useState("");
	const previewcolor = useSelector((state) => state.previewColorReducer);
	const [varColorObj, setVarColorObj] = useState({
		callToActionColor: "",
		hoverColor: "",
		columnHeaderAccentColor: "",
		linkColor: "",
	});

	// Modal Functions
	const [requestMoreModal, setRequestMoreModal] = useState(false);
	const [showDropModal, setShowDropModal] = useState(false);
	const [emailFriendModal, setEmailFriendModal] = useState(false);
	const [scheduleViewing, setOpenScheduleViewing] = useState(false);
	const [isModalVisible6, setIsModalVisible6] = useState(false);
	const [isModalVisibleFullscreen, setIsModalVisibleFullscreen] =
		useState(false);
	const [gfInventoryIdDetails, setGfInventoryIdDetails] = useState(0);
	const [studBackingTypeList, setStudBackingTypeList] = useState([]);
	const [studMetalColorList, setStudMetalColorList] = useState([]);
	const [studMetalTypeList, setStudMetalTypeList] = useState([]);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [dataLoading, setDataLoading] = useState(false);
	const [socialData, setSocialData] = useState();
	const [locationData, setLocationData] = useState([]);
	const [showSocialIcons, setShowSocialIcons] = useState(false);
	const [mountDetails, setMountDetails] = useState({
		addToCart: "",
		settingNumber: "",
		mountingStyle: "",
		mountingHeader: "",
		mountingDescription: "",
		mountingValue: "",
		diamondHeader: "",
		diamondDiscription: "",
		diamondValue: "",
		diamondSku: "",
		diamondSku1: "",
		image: "",
		dealerID: "",
		diamondCarat: "",
		diamondCarat1: "",
		diamondCutGrade: "",
		diamondCutGrade1: "",
		diamondCut: "",
		diamondCut1: "",
		diamondcolor: "",
		diamondcolor1: "",
		diamondClarity: "",
		diamondClarity1: "",
		diamondDepth: "",
		diamondDepth1: "",
		diamondTableMea: "",
		diamondTableMea1: "",
		diamondMeasure: "",
		diamondMeasure1: "",
		diamondCertificate: "",
		diamondCertificate1: "",
		diamondPrice: "",
		diamondPrice1: "",
		diamondVendorSku: "",
		diamondVendorSku1: "",
		manuID: "",
	});

	//Input Field States
	const [completeEarringInputData, setCompleteEarringInputData] = useState({
		metalType: "",
		metalColor: "",
		backingType: "",
	});
	const [settingIdForRequest, setSettingIdForRequest] = useState("");
	//Modal Input Fields States
	const [dropHintInputData, setDropHintInputData] =
		useState(initialDropAHintData);
	const [dropHintInputDataValidation, setDropHintInputDataValidation] =
		useState(initialDropAHintDataVal);
	const [requestMoreInfoInputData, setRequestMoreInfoInputData] = useState(
		initialRequestMoreInfoData
	);
	const [
		requestMoreInfoInputDataValidation,
		setRequestMoreInfoInputDataValidation,
	] = useState(initialRequestMoreInfoDataVal);
	const [emailFriendInputData, setEmailFriendInputData] = useState(
		initialEmailAFriendInputData
	);
	const [emailFriendInputDataValidation, setEmailFriendInputDataValidation] =
		useState(initialEmailAFriendInputDataVal);
	const [locationDetails, setLocationDetails] = useState([]);
	const [scheduleViewingInputData, setScheduleViewingInputData] = useState(
		initialScheduleViewingInputData
	);
	const [confirmScheduleModal, setConfirmScheduleModal] = useState(false);
	const [
		scheduleViewingInputDataValidation,
		setScheduleViewingInputDataValidation,
	] = useState(initialScheduleViewingInputDataVal);

	useEffect(() => {
		if (paramdealerId) {
			handleGetColorsData(paramdealerId);
		}
	}, [previewcolor.toogle]);

	const handleGetColorsData = async (id) => {
		let inputData = {
			dealerID: Number(id),
			moduleName: "StudBuilder",
			hoverEffect: "",
			columnHeaderAccent: "",
			linkColor: "",
			actionButton: "",
		};
		try {
			await colorPanelService
				.GetHoverEffect(inputData)
				.then((response) => {
					let msg = response.data.message;
					let initialColorObj =
						response.data.responseData.initialHoverEffectList[0];
					let newColorObj =
						response.data.responseData.ringBuilder_HoverEffect_List[0];
					let colorObj = {
						hoverEffect: "",
						columnHeaderAccent: "",
						linkColor: "",
						actionButton: "",
					};

					if (msg == "Success") {
						if (initialColorObj) {
							let tempobj = colorObj;
							tempobj.hoverEffect = newColorObj
								? newColorObj.hoverEffect == ""
									? initialColorObj.hoverEffect
									: newColorObj.hoverEffect
								: initialColorObj.hoverEffect;
							tempobj.columnHeaderAccent = newColorObj
								? newColorObj.columnHeaderAccent == ""
									? initialColorObj.columnHeaderAccent
									: newColorObj.columnHeaderAccent
								: initialColorObj.columnHeaderAccent;
							tempobj.linkColor = newColorObj
								? newColorObj.linkColor == ""
									? initialColorObj.linkColor
									: newColorObj.linkColor
								: initialColorObj.linkColor;
							tempobj.actionButton = newColorObj
								? newColorObj.actionButton == ""
									? initialColorObj.actionButton
									: newColorObj.actionButton
								: initialColorObj.actionButton;

							dispatch(setGlobalFontFamily(newColorObj.chooseFont));

							if (tempobj) {
								setVarColorObj({
									...varColorObj,
									callToActionColor: tempobj.actionButton,
									hoverColor: tempobj.hoverEffect,
									columnHeaderAccentColor: tempobj.columnHeaderAccent,
									linkColor: tempobj.linkColor,
								});
								lightOrDark(tempobj.columnHeaderAccent, "header");
								lightOrDark(tempobj.callToActionColor, "button");
								lightOrDark(tempobj.hoverColor, "hover");
							} else {
								setVarColorObj({
									...varColorObj,
									callToActionColor: initialColorObj.actionButton,
									hoverColor: initialColorObj.hoverEffect,
									columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
									linkColor: initialColorObj.linkColor,
								});
								lightOrDark(initialColorObj.columnHeaderAccent, "header");
								lightOrDark(initialColorObj.callToActionColor, "button");
								lightOrDark(initialColorObj.hoverColor, "hover");
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	function lightOrDark(color, type) {
		// Variables for red, green, blue values
		let r, g, b, hsp;

		if (color) {
			// Check the format of the color, HEX or RGB?
			if (color.match(/^rgb/)) {
				// If RGB --> store the red, green, blue values in separate variables
				color = color.match(
					/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
				);

				r = color[1];
				g = color[2];
				b = color[3];
			} else {
				// If hex --> Convert it to RGB: http://gist.github.com/983661
				color = +(
					"0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
				);

				r = color >> 16;
				g = (color >> 8) & 255;
				b = color & 255;
			}

			// HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
			hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

			// Using the HSP value, determine whether the color is light or dark
			// if hsp> 127.5 then it is dark
			if (hsp > 127.5) {
				if (type == "header") {
					setIsLightHeaderColor(true);
				} else if (type == "button") {
					setIsLightButtonColor(true);
				} else {
					setIsLightHoverColor(true);
				}
			} else {
				if (type == "header") {
					setIsLightHeaderColor(false);
				} else if (type == "button") {
					setIsLightButtonColor(false);
				} else {
					setIsLightHoverColor(false);
				}
			}
		}
	}

	const Wrapper = styled.div`
		& .whole__shapes .shapes__block .image:hover {
			border: 1px solid ${varColorObj.hoverColor};
		}

		& .whole__shapes .shapes__block:hover .image {
			border: 1px solid ${varColorObj.hoverColor};
		}

		& .whole__shapes .shapes__block .image .selected {
			border: 1px solid ${varColorObj.linkColor};
		}

		& .whole__shapes .shapes__block.selected .image {
			border: 1px solid ${varColorObj.linkColor};
		}

		& .img__block .imgselct__div:hover {
			border-bottom: 2px solid ${varColorObj.hoverColor};
		}

		& .img__block .imgselct__div.selected {
			border-bottom: 2px solid ${varColorObj.linkColor};
		}

		& .selection__block .selection__box:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background-color: ${varColorObj.hoverColor};
		}
		& .selection__block .selection__box.selected {
			border: 1px solid ${varColorObj.linkColor};
			background-color: ${varColorObj.linkColor};
		}

		& .ant-table-thead > tr > th {
			background: ${varColorObj.columnHeaderAccentColor} !important;
			height: 50px;
			color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
			line-height: 14px;
		}

		& .ant-btn-primary {
			background: ${varColorObj.callToActionColor} !important;
			border-color: ${varColorObj.callToActionColor} !important;
		}
		& .ant-btn-primary:hover {
			background: ${varColorObj.hoverColor} !important;
			border-color: ${varColorObj.hoverColor} !important;
		}

		& .primary-btn {
			background: ${varColorObj.callToActionColor} !important;
			border-radius: 5px !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .primary-btn:hover {
			background: ${varColorObj.hoverColor} !important;
			border-radius: 5px !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .ant-btn {
			background: ${varColorObj.callToActionColor} !important;
			border-radius: 5px !important;
			color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}

		& .ant-btn:hover {
			background: ${varColorObj.hoverColor} !important;
			border-radius: 5px !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
			border: none;
			padding: 0px 10px;
			height: 35px;
		}
		& .ant-input-search-button {
			background: #fff !important;
			border: 1px solid #d9d9d9;
			border-radius: 0 !important;
			height: 32px;
			border-left: 0;
		}

		& .ant-input-search-button:hover {
			background: #fff !important;
			border: 1px solid #d9d9d9;
			border-radius: 0 !important;
			height: 32px;
			border-left: 0;
		}
		& .linkText {
			color: ${varColorObj.linkColor};
			text-decoration: none;
			cursor: pointer;
		}

		& .linkText:hover {
			color: ${varColorObj.hoverColor};
		}

		& .iframe__block .itemdetails__btns p:hover img {
			filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
				brightness(93%) contrast(89%);
			color: ${varColorObj.hoverColor};
		}

		& .iframe__block .itemdetails__btns p:hover span {
			color: ${varColorObj.hoverColor};
		}

		& .ant-pagination-item-active {
			border: 1px solid ${varColorObj.linkColor} !important;
		}

		& .ant-pagination-item:hover {
			border: 1px solid ${varColorObj.hoverColor};
		}
		& .nav-tabs .nav-link.active {
			background: ${varColorObj.columnHeaderAccentColor} !important;
			color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .list_icon.active {
			border: 1px solid ${varColorObj.callToActionColor};
			background: ${varColorObj.callToActionColor} !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .grid_icon.active {
			border: 1px solid ${varColorObj.callToActionColor};
			background: ${varColorObj.callToActionColor} !important;
			color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .grid_icon:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background: ${varColorObj.hoverColor} !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .list_icon:hover {
			border: 1px solid ${varColorObj.hoverColor};
			background: ${varColorObj.hoverColor} !important;
			color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
		}

		& .ant-input-search-button {
			color: rgba(0, 0, 0, 0.45) !important;
		}
		& .ant-input-search-button:hover {
			color: rgba(0, 0, 0, 0.45) !important;
		}
	`;

	/* Color Individually Ends */

	useEffect(() => {
		if (paramdealerId) {
			handleGetstudBuilderOptiondetails(paramdealerId);

			setDealerId(paramdealerId);
			DiamondPair(gfInventoryID, paramdealerId, 0, PairId, "$");
			handleGetItemDetails(paramdealerID2, gfInventoryID, styleNumber);
			handleGetDealerLocationData(paramdealerId, paramdealerId1);
			GetLocationLoadDetails(paramdealerId);
			setGfInventoryIdDetails(gfInventoryID);

			setMountDetails((prevState) => ({
				...prevState,
				diamondValue: totalPrice,
				dealerID: paramdealerId,
			}));
		}
	}, []);

	// Options

	const initialOptionsData = {
		studbuilderLinkURL: "",
		internalUseLink: true,
		showOnlyDiamondSearch: true,
		showDealerDiamondsSKU: true,
		showScheduleViewing: true,
		showAddress: true,
		showCallForPrice: false,
		applyGstTax: true,
		gstTaxValue: "",
		showPinterestShare: true,
		showTwitterShare: true,
		showFacebookShare: true,
		showFacebookLike: true,
		showAddToCartButton: true,
		ShoppingCartURL: "",
		showViewCartButton: true,
		ShowInstagramPlus: true,
	};
	const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);

	const handleGetstudBuilderOptiondetails = (id) => {
		let inputData = {
			dealerID: id,
		};
		try {
			SelectYourDiamondService.GetstudBuilderOptiondetails(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData[0];
					if (message == "Success") {
						if (responseData) {
							if (response.data.responseData.length > 0) {
								setMountDetails((prevState) => ({
									...prevState,
									addToCart: responseData.diamondstudshoppingurl,
								}));
								setMyOptionsData((prevState) => ({
									...prevState,
									studbuilderLinkURL: responseData.studBuilderURL,
									internalUseLink: responseData.internalLinkSB,
									showOnlyDiamondSearch: responseData.onlyDiamondSearch,
									showDealerDiamondsSKU: responseData.stud_ShowSKU,
									showScheduleViewing: responseData.showScheduleView,
									showAddress: responseData.showAddresses,
									showCallForPrice: responseData.callForPricestud,
									applyGstTax: responseData.showGstPrice,
									gstTaxValue: responseData.taxRate,
									showPinterestShare: responseData.showPinterestShare,
									showTwitterShare: responseData.showTwitterShare,
									showFacebookShare: responseData.showFacebookShare,
									showFacebookLike: responseData.showFacebookLike,
									showAddToCartButton: responseData.showAddToCartButtonStud,
									ShoppingCartURL: responseData.diamondstudshoppingurl,
									showViewCartButton: responseData.showViewCartButtonStud,
									ShowInstagramPlus: responseData.showInstagramShare,
								}));
							}
						}
					}
				})
				.catch((error) => {
					console.log(error);
					setMyOptionsData((prevState) => ({
						showOnlyDiamondSearch: false,
					}));
				});
		} catch (error) {
			console.log(error);
			setMyOptionsData((prevState) => ({
				showOnlyDiamondSearch: false,
			}));
		}
	};

	const { Option } = Select;

	const images = [
		{
			original: `mountDetails.image`,
			thumbnail: `mountDetails.image`,
		},
	];

	function renderLeftNav(onClick, disabled) {
		return (
			<button
				type="button"
				className="image-gallery-left-nav"
				aria-label="Prev Slide"
				disabled={disabled}
				onClick={onClick}>
				<img src={leftArrow} />
			</button>
		);
	}

	function renderRightNav(onClick, disabled) {
		return (
			<button
				type="button"
				className="image-gallery-right-nav"
				aria-label="Next Slide"
				disabled={disabled}
				onClick={onClick}>
				<img src={rightArrow} />
			</button>
		);
	}

	const showRequestMoreInfoModal = () => {
		setRequestMoreModal(true);
		handleScrollToTop();
	};

	const showDropHintModal = () => {
		handleScrollToTop();
		setShowDropModal(true);
	};

	const hideDropHintModal = () => {
		setShowDropModal(false);
		setDropHintInputData(initialDropAHintData);
		setDropHintInputDataValidation(initialDropAHintDataVal);
	};

	const closeRequestMoreModal = () => {
		setRequestMoreModal(false);
		setRequestMoreInfoInputData(initialRequestMoreInfoData);
		setRequestMoreInfoInputDataValidation(initialRequestMoreInfoDataVal);
	};

	const showEmailFriendModal = () => {
		setEmailFriendModal(true);
		handleScrollToTop();
	};
	const closeEmailFriendModal = () => {
		setEmailFriendModal(false);
		setEmailFriendInputData(initialEmailAFriendInputData);
		setEmailFriendInputDataValidation(initialEmailAFriendInputDataVal);
	};
	const showScheduleViewingModal = () => {
		handleScrollToTop();
		setOpenScheduleViewing(true);
	};
	const closeScheduleViewingModal = () => {
		setOpenScheduleViewing(false);
		setScheduleViewingInputData(initialScheduleViewingInputData);
		setScheduleViewingInputDataValidation({
			nameval: "",
		});
	};

	const handleSubmitSheduleViewing = () => {
		let isValid = handleValidationSchedule();
		if (isValid) {
			handleScrollToTop();
			handleSaveScheduleViewing();
		}
	};
	const handleCancel6 = () => {
		setIsModalVisible6(false);
	};

	const showModalFullscreen = () => {
		setIsModalVisibleFullscreen(true);
	};

	const handleCancelFullscreen = () => {
		setIsModalVisibleFullscreen(false);
	};

	function handlePrintDiamondList() {
		window.print();
	}

	const handleCompleteEarringInputChange = (e, name) => {
		if (name == "metalType") {
			setCompleteEarringInputData({
				[name]: e,
			});
		} else {
			setCompleteEarringInputData({
				...completeEarringInputData,
				[name]: e,
			});
		}
		if (name == "metalType") {
			GetStudDropDownOnchangeData(
				"All",
				"1",
				mountDetails.dealerID,
				gfInventoryIdDetails,
				e,
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"52",
				""
			);
			setCompleteEarringInputData((prevState) => ({
				...prevState,
				metalType: e,
			}));
		}
		if (name == "metalColor") {
			GetStudDropDownOnchangeData(
				"All",
				"1",
				mountDetails.dealerID,
				gfInventoryIdDetails,
				completeEarringInputData.metalType,
				e,
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"",
				"52",
				""
			);
			setCompleteEarringInputData((prevState) => ({
				...prevState,
				metalColor: e,
			}));
		}
	};

	const handleDropHintChange = (e, name, type) => {
		setDropHintInputData({
			...dropHintInputData,
			[name]: e.target.value,
		});
		if (name == "name") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				nameval: "",
			}));
		}
		if (name == "email") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				emailval: "",
			}));
		}
		if (name == "recipientName") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				recipientNameval: "",
			}));
		}
		if (name == "recipientEmail") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				recipientEmailval: "",
			}));
		}
		if (name == "giftReason") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				giftReasonval: "",
			}));
		}
		if (name == "personalMessage") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				personalMessageval: "",
			}));
		}
		if (name == "giftDeadline") {
			setDropHintInputDataValidation((prevdata) => ({
				...prevdata,
				giftDeadlineval: "",
			}));
		}
	};

	const handleSubmitDropHint = () => {
		handleSaveDropHint();
	};

	const handleRequestMoreInfoChange = (e, name, type) => {
		setRequestMoreInfoInputData({
			...requestMoreInfoInputData,
			[name]: e.target.value,
		});

		if (name == "name") {
			setRequestMoreInfoInputDataValidation((prevdata) => ({
				...prevdata,
				nameval: "",
			}));
		}
		if (name == "email") {
			setRequestMoreInfoInputDataValidation((prevdata) => ({
				...prevdata,
				emailval: "",
			}));
		}
		if (name == "phoneNumber") {
			setRequestMoreInfoInputDataValidation((prevdata) => ({
				...prevdata,
				phoneNumberval: "",
			}));
		}
	};

	const handleSubmitRequestMoreInfo = () => {
		handleSaveRequestMoreInfo();
	};

	const handleEmailFriendChange = (e, name) => {
		setEmailFriendInputData({
			...emailFriendInputData,
			[name]: e.target.value,
		});

		if (name == "name") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				nameval: "",
			}));
		}
		if (name == "email") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				emailval: "",
			}));
		}
		if (name == "friendName") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				friendNameval: "",
			}));
		}

		if (name == "friendEmail") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				friendEmailval: "",
			}));
		}

		if (name == "personalMessage") {
			setEmailFriendInputDataValidation((prevdata) => ({
				...prevdata,
				personalMessageval: "",
			}));
		}
	};

	const handleSubmitEmailFriend = () => {
		handleSaveEmailFriend();
	};
	const handleScheduleViewingChange = (e, name, type) => {
		if (type == "select") {
			setScheduleViewingInputData({
				...scheduleViewingInputData,
				[name]: e,
			});
			if (name == "location") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					locationVal: "",
				}));
			}

			if (name == "appointmentTime") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					appointmentTimeVal: "",
				}));
			}
		} else {
			setScheduleViewingInputData({
				...scheduleViewingInputData,
				[name]: e.target.value,
			});

			if (name == "name") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					nameval: "",
				}));
			}
			if (name == "email") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					emailVal: "",
				}));
			}
			if (name == "phoneNumber") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					phoneNumberVal: "",
				}));
			}
			if (name == "message") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					messageVal: "",
				}));
			}
			if (name == "appointmentDate") {
				setScheduleViewingInputDataValidation((prevdata) => ({
					...prevdata,
					appointmentDateVal: "",
				}));
			}
		}
	};

	const handleValidationSchedule = () => {
		const {
			name,
			email,
			phoneNumber,
			location,
			appointmentDate,
			appointmentTime,
			address,
			message,
		} = scheduleViewingInputData;

		const scheduleViewingInputDataValidation = {
			nameval: "",
			emailVal: "",
			phoneNumberVal: "",
			locationVal: "",
			appointmentDateVal: "",
			appointmentTimeVal: "",
			addressVal: "",
			messageVal: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			scheduleViewingInputDataValidation.nameval = t("Name is Compulsory");
		} else {
			scheduleViewingInputDataValidation.nameval = "";
		}
		if (!location) {
			isValid = false;
			scheduleViewingInputDataValidation.locationVal = t(
				"Location is Compulsory"
			);
		}

		if (!appointmentDate) {
			isValid = false;
			scheduleViewingInputDataValidation.appointmentDateVal = t(
				"Appointment Date is Compulsory"
			);
		}

		if (!appointmentTime) {
			isValid = false;
			scheduleViewingInputDataValidation.appointmentTimeVal = t(
				"Appointment Time is Compulsory"
			);
		}
		if (!message) {
			isValid = false;
			scheduleViewingInputDataValidation.messageVal = t(
				"Message is Compulsory"
			);
		}

		let validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email) {
			isValid = false;
			scheduleViewingInputDataValidation.emailVal = t(
				"Email address is compulsory"
			);
		} else if (!email.match(validRegex)) {
			isValid = false;
			scheduleViewingInputDataValidation.emailVal = t(
				"Email address is invalid"
			);
		} else {
			scheduleViewingInputDataValidation.emailVal = "";
		}
		if (!phoneNumber) {
			isValid = false;
			scheduleViewingInputDataValidation.phoneNumberVal = t(
				"Phone number is compulsory"
			);
		} else if (
			(phoneNumber.length >= 10 && phoneNumber.includes("-")) ||
			(phoneNumber.length >= 10 && phoneNumber.includes(",")) ||
			(phoneNumber.length >= 10 && phoneNumber.includes("+"))
		) {
			scheduleViewingInputDataValidation.phoneNumberVal = "";
		} else if (!/^(?!0{10,15})(\+\d{1,3}[-]?)?\d{10,15}$/.test(phoneNumber)) {
			isValid = false;
			scheduleViewingInputDataValidation.phoneNumberVal = t(
				"Phone number is invalid"
			);
		} else {
			scheduleViewingInputDataValidation.phoneNumberVal = "";
		}

		setScheduleViewingInputDataValidation(scheduleViewingInputDataValidation);
		return isValid;
	};

	const handleValidationReuestInfo = () => {
		const { name, email, phoneNumber } = requestMoreInfoInputData;

		const requestMoreInfoInputDataValidation = {
			nameval: "",
			emailval: "",
			phoneNumberval: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			requestMoreInfoInputDataValidation.nameval = t("Name is Compulsory");
		} else {
			requestMoreInfoInputDataValidation.nameval = "";
		}
		let validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email.trim()) {
			isValid = false;
			requestMoreInfoInputDataValidation.emailval = t("Email is Compulsory");
		} else if (!email.match(validRegex)) {
			isValid = false;
			requestMoreInfoInputDataValidation.emailval = t("Email is Invalid");
		} else {
			requestMoreInfoInputDataValidation.emailval = "";
		}

		if (!phoneNumber.trim()) {
			isValid = false;
			requestMoreInfoInputDataValidation.phoneNumberval = t(
				"Phone Number is Compulsory"
			);
		} else {
			requestMoreInfoInputDataValidation.phoneNumberval = "";
		}
		setRequestMoreInfoInputDataValidation(requestMoreInfoInputDataValidation);
		return isValid;
	};

	const handleValidationDrop = () => {
		const {
			name,
			email,
			recipientName,
			recipientEmail,
			giftReason,
			personalMessage,
			giftDeadline,
		} = dropHintInputData;

		const dropHintInputDataValidation = {
			nameval: "",
			emailval: "",
			recipientNameval: "",
			recipientEmailval: "",
			giftReasonval: "",
			personalMessageval: "",
			giftDeadlineval: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			dropHintInputDataValidation.nameval = t("Name is Compulsory");
		} else {
			dropHintInputDataValidation.nameval = "";
		}

		let validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email.trim()) {
			isValid = false;
			dropHintInputDataValidation.emailval = t("Email is Compulsory");
		} else if (!email.match(validRegex)) {
			isValid = false;
			dropHintInputDataValidation.emailval = t("Email is Invalid");
		} else {
			dropHintInputDataValidation.emailval = "";
		}

		if (!recipientName.trim()) {
			isValid = false;
			dropHintInputDataValidation.recipientNameval = t(
				"Recipient's Name is Compulsory"
			);
		} else {
			dropHintInputDataValidation.recipientNameval = "";
		}

		if (!recipientEmail.trim()) {
			isValid = false;
			dropHintInputDataValidation.recipientEmailval = t(
				"Recipient's E-Mail is Compulsory"
			);
		} else if (!recipientEmail.match(validRegex)) {
			isValid = false;
			dropHintInputDataValidation.recipientEmailval = t(
				"Recipient's E-Mail is Invalid"
			);
		} else {
			dropHintInputDataValidation.recipientEmailval = "";
		}

		if (!giftReason.trim()) {
			isValid = false;
			dropHintInputDataValidation.giftReasonval = t("Reason is Compulsory");
		} else {
			dropHintInputDataValidation.giftReasonval = "";
		}

		if (!personalMessage.trim()) {
			isValid = false;
			dropHintInputDataValidation.personalMessageval = t(
				"Personal Message is Compulsory"
			);
		} else {
			dropHintInputDataValidation.personalMessageval = "";
		}

		if (!giftDeadline.trim()) {
			isValid = false;
			dropHintInputDataValidation.giftDeadlineval = t(
				"Gift Deadline is Compulsory"
			);
		} else {
			dropHintInputDataValidation.giftDeadlineval = "";
		}

		setDropHintInputDataValidation(dropHintInputDataValidation);
		return isValid;
	};

	const handleValidationEmailFriend = () => {
		const { name, email, friendName, friendEmail, personalMessage } =
			emailFriendInputData;

		const emailFriendInputDataValidation = {
			nameval: "",
			emailval: "",
			friendNameval: "",
			friendEmailval: "",
			personalMessageval: "",
		};
		let isValid = true;

		if (!name.trim()) {
			isValid = false;
			emailFriendInputDataValidation.nameval = t("Name is Compulsory");
		} else {
			emailFriendInputDataValidation.nameval = "";
		}

		let validRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!email.trim()) {
			isValid = false;
			emailFriendInputDataValidation.emailval = t("Email is Compulsory");
		} else if (!email.match(validRegex)) {
			isValid = false;
			emailFriendInputDataValidation.emailval = t("Email is Invalid");
		} else {
			emailFriendInputDataValidation.emailval = "";
		}

		if (!friendName.trim()) {
			isValid = false;
			emailFriendInputDataValidation.friendNameval = t(
				"Friend's Name is Compulsory"
			);
		} else {
			emailFriendInputDataValidation.friendNameval = "";
		}

		if (!friendEmail.trim()) {
			isValid = false;
			emailFriendInputDataValidation.friendEmailval = t(
				"Friend's E-Mail is Compulsory"
			);
		} else if (!friendEmail.match(validRegex)) {
			isValid = false;
			emailFriendInputDataValidation.friendEmailval = t(
				"Friend's E-Mail is Invalid"
			);
		} else {
			emailFriendInputDataValidation.friendEmailval = "";
		}

		if (!personalMessage.trim()) {
			isValid = false;
			emailFriendInputDataValidation.personalMessageval = t(
				"Personal Message is Compulsory"
			);
		} else {
			emailFriendInputDataValidation.personalMessageval = "";
		}
		setEmailFriendInputDataValidation(emailFriendInputDataValidation);
		return isValid;
	};

	const GetLocationLoadDetails = (id) => {
		let inputData = {
			dealerID: id,
		};
		try {
			ChooseSettingServices.GetLocationDetails(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData;
					if (message == "Success") {
						setLocationDetails(responseData.Table);
					} else {
						setLocationDetails([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleSaveScheduleViewing = async () => {
		const locationName = locationDetails?.find(
			(e) => e?.LocationId == scheduleViewingInputData?.location
		)?.["LocationName"];
		let valuePrice = parseInt(mountDetails?.finalPrice2);
		let actualValue = `${currencySymbol}${valuePrice}`;

		let inputData = {
			retailerID: mountDetails.manuID,
			dealerID: mountDetails.dealerID,
			emailChk: scheduleViewingInputData.email,
			schedulPnone: scheduleViewingInputData.phoneNumber,
			appntMsg: scheduleViewingInputData.message,
			schedulName: scheduleViewingInputData.name,
			appntDate: scheduleViewingInputData.appointmentDate,
			hndAppntTime: scheduleViewingInputData.appointmentTime,
			diD2: gfInventoryID.toString(),
			did:
				mountDetails?.diamondVendorSku + "," + mountDetails?.diamondVendorSku1,
			shape: mountDetails.diamondCut,
			ctw: "",
			strDiamondLinkURL: "",
			step: "",
			themeName: "",
			loadFromMasterlink: true,
			type: "Jewelry",
			metalType: completeEarringInputData.metalType
				? completeEarringInputData.metalType
				: "",
			metalColor: completeEarringInputData.metalColor
				? completeEarringInputData.metalColor
				: "",
			price2: myOptionsData.showCallForPrice
				? t("Call For Price")
				: actualValue.toString() || "",
			price1: myOptionsData.showCallForPrice
				? t("Call For Price")
				: actualValue.toString() || "",
			location: locationName,
		};

		setButtonLoading(true);
		try {
			completeYourRing
				.SaveScheduleAViewing(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success(
							t("Your request has been sent to GemFind.")
						);
						setButtonLoading(false);
						setOpenScheduleViewing(false);
						setScheduleViewingInputData(initialScheduleViewingInputData);
						setConfirmScheduleModal(false);
					} else {
						NotificationManager.error(
							t("Your request has not been sent to GemFind.")
						);
						setButtonLoading(false);
						setOpenScheduleViewing(false);
						setScheduleViewingInputData(initialScheduleViewingInputData);
						setConfirmScheduleModal(false);
					}
					handleScrollToTop();
				})
				.catch((error) => {
					setButtonLoading(false);
					setOpenScheduleViewing(false);
					setScheduleViewingInputData(initialScheduleViewingInputData);
					setConfirmScheduleModal(false);
					handleScrollToTop();
					console.log(error);
				});
		} catch (error) {
			setButtonLoading(false);
			setOpenScheduleViewing(false);
			setScheduleViewingInputData(initialScheduleViewingInputData);
			setConfirmScheduleModal(false);
			handleScrollToTop();
			console.log(error);
		}
	};

	const handleSaveRequestMoreInfo = async () => {
		let isValid = handleValidationReuestInfo();

		if (!isValid) {
			setRequestMoreModal(true);
			return false;
		}

		let valuePrice = parseInt(mountDetails?.finalPrice2);
		let actualValue = `${currencySymbol}${valuePrice}`;

		let inputData = {
			dealerID: mountDetails.dealerID,
			email: requestMoreInfoInputData.email,
			phone: requestMoreInfoInputData.phoneNumber,
			byEmail:
				requestMoreInfoInputData.contactPreference == "byEmail" ? true : false,
			comments: requestMoreInfoInputData.personalMessage,
			name: requestMoreInfoInputData.name,
			diD2: settingIdForRequest, // gfInventoryID.toString(),
			did:
				mountDetails?.diamondVendorSku + "," + mountDetails?.diamondVendorSku1,
			retailerID: mountDetails.manuID.toString(),
			skuNo2: mountDetails.diamondSku1,
			skuNo: mountDetails.diamondSku,
			carat2: mountDetails.diamondCarat1,
			carat: mountDetails.diamondCarat,
			cutGrade2: mountDetails.diamondCutGrade1,
			cutGrade: mountDetails.diamondCutGrade,
			cut2: mountDetails.diamondCut1,
			cut: mountDetails.diamondCut,
			color2: mountDetails.diamondcolor1,
			color: mountDetails.diamondcolor,
			clarity2: mountDetails.diamondClarity1,
			clarity: mountDetails.diamondClarity,
			depth2: mountDetails.diamondDepth1,
			depth: mountDetails.diamondDepth,
			tableMes2: mountDetails.diamondTableMea1,
			tableMes: mountDetails.diamondTableMea,
			measurements2: mountDetails.diamondMeasure1,
			measurements: mountDetails.diamondMeasure,
			certificate: mountDetails.diamondCertificate,
			certificate2: mountDetails.diamondCertificate1,
			callForPrice: true,
			price2: myOptionsData.showCallForPrice
				? t("Call For Price")
				: actualValue.toString() || "",
			price: myOptionsData.showCallForPrice
				? t("Call For Price")
				: actualValue.toString() || "",
			vendorSKUNo1: mountDetails.diamondVendorSku,
			vendorSKUNo2: mountDetails.diamondVendorSku1,
			metalType: completeEarringInputData?.metalType ?? "",
			metalColor: completeEarringInputData?.metalColor ?? "",
		};
		setButtonLoading(true);
		try {
			completeYourRing
				.ComplateEaringsStudBuilderRequestMoreInfo(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success(
							t(
								"Your request has been sent to GemFind. A representative will be contacting you shortly."
							)
						);
						setButtonLoading(false);
						setRequestMoreModal(false);
						setRequestMoreInfoInputData(initialRequestMoreInfoData);
					} else {
						NotificationManager.error(
							t("Your request has not been sent to GemFind")
						);
						setButtonLoading(false);
						setRequestMoreModal(false);
						setRequestMoreInfoInputData(initialRequestMoreInfoData);
					}
					handleScrollToTop();
				})
				.catch((error) => {
					setButtonLoading(false);
					console.log(error);
					setRequestMoreModal(false);
					setRequestMoreInfoInputData(initialRequestMoreInfoData);
					handleScrollToTop();
				});
		} catch (error) {
			setButtonLoading(false);
			console.log(error);
			setRequestMoreModal(false);
			setRequestMoreInfoInputData(initialRequestMoreInfoData);
			handleScrollToTop();
		}
	};

	const handleSaveDropHint = async () => {
		let isValid = handleValidationDrop();

		if (!isValid) {
			setShowDropModal(true);
			return false;
		}

		let inputData = {
			dealerID: mountDetails.dealerID,
			name: dropHintInputData.name,
			mail: dropHintInputData.email,
			recName: dropHintInputData.recipientName,
			recEmail: dropHintInputData.recipientEmail,
			giftReason: dropHintInputData.giftReason,
			reason: dropHintInputData.personalMessage,
			date: dropHintInputData.giftDeadline,
			loadFromMasterlink: true,
			strDiamondLinkURL: "",
			sid: "",
			did: "",
			shape: "",
			ctw: "",
			step: "",
			themeName: "",
			ViewItemURL: window.location.href.toString(),
		};
		setButtonLoading(true);
		try {
			completeYourRing
				.ComplateEaringsSendStudDropHint(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success(
							t("Your request has been sent to GemFind.")
						);
						setButtonLoading(false);
						setShowDropModal(false);
						setDropHintInputData(initialDropAHintData);
					} else {
						NotificationManager.error(
							t("Your request has not been sent to GemFind.")
						);
						setButtonLoading(false);
						setShowDropModal(false);
						setDropHintInputData(initialDropAHintData);
					}
					handleScrollToTop();
				})
				.catch((error) => {
					NotificationManager.error(t("Could Not Add Record"));
					setButtonLoading(false);
					setShowDropModal(false);
					console.log(error);
					setDropHintInputData(initialDropAHintData);
					handleScrollToTop();
				});
		} catch (error) {
			NotificationManager.error(t("Sorry we had an error"));
			setButtonLoading(false);
			setShowDropModal(false);
			console.log(error);
			setDropHintInputData(initialDropAHintData);
			handleScrollToTop();
		}
	};
	const DiamondPair = async (
		gfInventoryID,
		dealerlink,
		currencyrate,
		pair,
		countrytax
	) => {
		let inputData = {
			gfInventoryID: gfInventoryID,
			dealerLink: dealerlink,
			currencyRate: currencyrate,
			pairID: pair,
			countryTaxRate: countrytax,
		};
		try {
			completeYourRing
				.DiamondPair(inputData)
				.then((response) => {
					let responseData = response.data.responseData;
					if (responseData.length > 0) {
						setCurrencySymbol(
							`${responseData[0]?.currencycode ?? ""}${
								responseData[0].currencySign
							}`
						);
						setMountDetails((prevState) => ({
							...prevState,
							diamondHeader: responseData[0].totalCaratWeight,
							diamondDiscription: responseData[0].diamondName,
							diamondSku: responseData[0].skuNo,
							diamondSku1: responseData[0].skuNo2,
							diamondCarat: responseData[0].carat,
							diamondCarat1: responseData[0].carat2,
							diamondCutGrade: responseData[0].cutGrade,
							diamondCutGrade1: responseData[0].cutGrade2,
							diamondCut: responseData[0].cut,
							diamondCut1: responseData[0].cut2,
							diamondcolor: responseData[0].color,
							diamondcolor1: responseData[0].color2,
							diamondClarity: responseData[0].clarity,
							diamondClarity1: responseData[0].clarity2,
							diamondDepth: responseData[0].depth,
							diamondDepth1: responseData[0].depth2,
							diamondTableMea: responseData[0].tableMes,
							diamondTableMea1: responseData[0].tableMes2,
							diamondMeasure: responseData[0].measurements,
							diamondMeasure1: responseData[0].measurements2,
							diamondCertificate: responseData[0].certificate,
							diamondCertificate1: responseData[0].certificate2,
							diamondPrice: responseData[0].price,
							diamondPrice1: responseData[0].price2,
							diamondVendorSku: responseData[0].vendorSKUNo1,
							diamondVendorSku1: responseData[0].vendorSKUNo2,
							manuID: responseData[0].manuID,
							finalPrice2: responseData[0]?.finalPrice2,
						}));
						handleCompleteYourEarringSocialLinks(
							dealerlink,
							gfInventoryID,
							responseData[0].skuNo ? responseData[0].skuNo : "",
							responseData[0].skuNo2 ? responseData[0].skuNo2 : ""
						);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const handleCompleteYourEarringSocialLinks = async (
		dealerId,
		gfInventoryID,
		Diamond1,
		Diamond2
	) => {
		let inputData = {
			dealerId: dealerId.toString(),
			gfInventoryId: gfInventoryID.toString(),
			diD1: Diamond1.toString(),
			diD2: Diamond2.toString(),
		};
		try {
			completeYourRing
				.CompleteYourEarringSocialLinks(inputData)
				.then((response) => {
					let msg = response.data.message;
					let responseData = response.data.responseData;

					if ((msg = "Success")) {
						setSocialData(responseData);
					} else {
						setSocialData({});
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const GetStudDropDownData = async (gfInventoryID, dealerId) => {
		let inputData = {
			gfInventoryID: gfInventoryID,
			dealerId: dealerId,
		};
		try {
			completeYourRing
				.GetStudDropDownData(inputData)
				.then((response) => {
					let msg = response.data.message;
					let responseData = response.data.responseData;

					if ((msg = "Success")) {
						setStudBackingTypeList(responseData.studBackingTypelst);
						setStudMetalColorList(responseData.studMetalColorlst);
						setStudMetalTypeList(responseData.studMetalTypelst);
					} else {
						setStudBackingTypeList([]);
						setStudMetalColorList([]);
						setStudMetalTypeList([]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	const GetStudDropDownOnchangeData = async (
		bind,
		all,
		dealer,
		product,
		configmetal,
		configcolor,
		configdicolor,
		configsttype,
		configno,
		configgemsto,
		configcarat,
		configdiamondqua,
		configpetype,
		configpeshape,
		configpesize,
		configpequality,
		configwidth,
		configthick,
		isvalue,
		jewcategory,
		countrytax
	) => {
		let inputData = {
			bindWhich: bind,
			all: all,
			dealerID: DealerID,
			productID: product,
			configMetalType: configmetal,
			configMetalColor: configcolor,
			configDiamondColor: configdicolor,
			configGemstoneType: configsttype,
			configNoOfGemstones: configno,
			configGemstoneShape: configgemsto,
			configCaratWeight: configcarat,
			configDiamondQuality: configdiamondqua,
			configPearlType: configpetype,
			configPearlShape: configpeshape,
			configPearlSize: configpesize,
			configPearlQuality: configpequality,
			configWidth: configwidth,
			configThickness: configthick,
			iValue: isvalue,
			jewelryCategory: jewcategory,
			countryTaxRate: countrytax,
			VendorID: paramdealerID2 ? paramdealerID2.toString() : "",
		};
		setDataLoading(true);
		try {
			completeYourRing
				.ChangeProductConfigurationStud(inputData)
				.then((response) => {
					let msg = response.data.message;
					let responseData = response.data.responseData;
					let newRe =
						responseData.changeProductConfigurationStudProdGemStoneProdDtls;

					if ((msg = "Success")) {
						setStudMetalColorList(
							responseData.studDropdowndataResp.studMetalColorlst
						);
						if (Object.keys(newRe && newRe).length > 0) {
							let arr = [];
							let obj = {};
							obj["original"] = newRe.imagePath;
							obj["thumbnail"] = newRe.imagePath;
							arr.push(obj);
							setMountDetails((prevdata) => ({
								...prevdata,
								mountingStyle:
									responseData
										.changeProductConfigurationStudProdGemStoneProdDtls.styleNo,
								mountingHeader:
									responseData
										.changeProductConfigurationStudProdGemStoneProdDtls
										.productName,
								mountingDescription:
									responseData
										.changeProductConfigurationStudProdGemStoneProdDtls
										.productDescription,
								mountingValue:
									responseData
										.changeProductConfigurationStudProdGemStoneProdDtls
										.finalPrice,
								image: arr,
							}));
							setDataLoading(false);
						}
						setDataLoading(false);
					} else {
						setDataLoading(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setDataLoading(false);
				});
		} catch (error) {
			console.log(error);
			setDataLoading(false);
		}
	};

	const handleSaveEmailFriend = async () => {
		let isValid = handleValidationEmailFriend();

		if (!isValid) {
			setEmailFriendModal(true);
			return false;
		}
		let inputData = {
			dealerID: mountDetails.dealerID,
			yourEmail: emailFriendInputData.email,
			tellFriendComment: emailFriendInputData.personalMessage,
			yourName: emailFriendInputData.name,
			yourFriendEmail: emailFriendInputData.friendEmail,
			yourFriendName: emailFriendInputData.friendName,
			did:
				mountDetails?.diamondVendorSku + "," + mountDetails?.diamondVendorSku1,
			diD2: mountDetails.diamondSku1.toString(),
			retailerID: mountDetails.manuID,
			byEmail: true,
			settingName: mountDetails.mountingHeader
				? mountDetails.mountingHeader
				: "",
			imagePath: mountDetails?.image?.[0]?.original ?? "",
			metalType: completeEarringInputData.metalType
				? completeEarringInputData.metalType
				: "",
			metalColor: completeEarringInputData.metalColor
				? completeEarringInputData.metalColor
				: "",
			gender: "",
			finTech: "",
			styleNo: mountDetails.mountingStyle,
			settingPrice: mountDetails.mountingValue
				? mountDetails.mountingValue
				: "",
			callForPrice: myOptionsData.showCallForPrice.toString(),
			currencySymbol: currencySymbol.toString(),
			deliveryTime: "",
			setting: mountDetails.mountingStyle,
			gfInventoryId: mountDetails.settingNumber,
			price: myOptionsData.showCallForPrice
				? t("Call For Price")
				: mountDetails?.finalPrice2?.toString() ?? "",
		};
		setButtonLoading(true);
		try {
			completeYourRing
				.ComplateEaringsStudBuilderEmailAFriend(inputData)
				.then((response) => {
					let msg = response.data.message;
					if (msg == "Success") {
						NotificationManager.success(
							t("An email has been sent to your friend,Thanks for sharing!")
						);
						setButtonLoading(false);
						setEmailFriendModal(false);
						setEmailFriendInputData(initialEmailAFriendInputData);
					} else {
						NotificationManager.error(
							t(
								"An email has not been  sent to your friend,Thanks for sharing!"
							)
						);
						setButtonLoading(false);
						setEmailFriendModal(false);
						setEmailFriendInputData(initialEmailAFriendInputData);
					}
					handleScrollToTop();
				})
				.catch((error) => {
					NotificationManager.error(t("Could Not Add Record"));
					setButtonLoading(false);
					setEmailFriendModal(false);
					setEmailFriendInputData(initialEmailAFriendInputData);
					handleScrollToTop();
					console.log(error);
				});
		} catch (error) {
			NotificationManager.error(t("Sorry we had an error"));
			setButtonLoading(false);
			setEmailFriendModal(false);
			setEmailFriendInputData(initialEmailAFriendInputData);
			console.log(error);
			handleScrollToTop();
		}
	};

	const handleGetDealerLocationData = (id, retailerId) => {
		try {
			let inputData = {
				dealerID: id.toString(),
				myRetailerID: retailerId,
			};
			ChooseSettingServices.GetDealerLocationData(inputData)
				.then((response) => {
					let message = response.data.message;
					let responseData = response.data.responseData[0];

					if (message == "Success") {
						if (responseData) {
							setLocationData(responseData);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (error) {
			console.log(error);
		}
	};

	// Date
	const disablePastDate = () => {
		const today = new Date();
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
		const yyyy = today.getFullYear();
		return yyyy + "-" + mm + "-" + dd;
	};

	const handleAddtoCart = (url) => {
		handleScrollToTop();
		let domainUrl = url?.match(
			/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/i
		);
		if (domainUrl && domainUrl.length > 1) {
			if (url.includes("https")) {
				window.open(url, "_blank");
			} else if (url.includes("http")) {
				window.open(url, "_blank");
			} else {
				window.open("https://" + url, "_blank");
			}
		} else {
			NotificationManager.error(t("Please Add Alternate Shopping Cart URL"));
		}
	};

	const handleOpenSocialData = (url) => {
		window.open(url);
	};

	// Child Product

	const handleGetItemDetails = async (id, settingId, settingNumber) => {
		let inputData = {
			gfInventoryID: settingId.toString(),
			dealerLink: id.toString(),
			metalType: "",
			metalColor: "",
			styleNumber: settingNumber.toString(),
			url: "",
			qurShape: "",
			qurCTW: "",
			themeName: "",
			qurDID: "",
			bindWhat: "",
			countryTaxRate: "",
			gemstoneQuality: "",
			gemstoneSize: "",
			caratMin: "",
			gemstoneShape: "",
			type: "",
			IsRingbuilder: false,
			loginId: paramdealerId.toString(),
		};
		setDataLoading(true);
		try {
			await completeYourRing
				.GetAppPrevDataonSelectYourSetting(inputData)
				.then((response) => {
					let msg = response.data.message;
					let responseData =
						response.data.responseData.getAppPrevDataonSelectYourSettingClass;
					if (msg == "Success") {
						if (responseData.length > 0) {
							let arr = [];
							let obj = {};
							obj["original"] = responseData[0].mainImageURL;
							obj["thumbnail"] = responseData[0].mainImageURL;
							arr.push(obj);

							setMountDetails((prevState) => ({
								...prevState,
								mountingStyle: responseData[0].settingNumber,
								mountingHeader: responseData[0].settingName,
								mountingDescription: responseData[0].description,
								mountingValue: responseData[0].cost,
								image: arr,
								settingNumber: responseData[0].settingID,
							}));
							// Metal Type and Color
							setSettingIdForRequest(responseData[0].settingID);
							if (responseData[0].metalColor.length > 0) {
								const color = responseData[0].metalColor;
								const colorsplit = color.split(",");
								setCompleteEarringInputData((prevState) => ({
									...prevState,
									metalColor: colorsplit[0],
								}));
							}
							if (responseData[0].metal.length > 0) {
								const metal = responseData[0].metal;
								const metalsplit = metal.split(",");
								setCompleteEarringInputData((prevState) => ({
									...prevState,
									metalType: metalsplit[0],
								}));
							}
							setDataLoading(false);
							GetStudDropDownData(gfInventoryID, paramdealerId);
						}
					}
				})
				.catch((error) => {
					console.log(error);
					setDataLoading(false);
				});
		} catch (error) {
			console.log(error);
			setDataLoading(false);
		}
	};

	const truncateSku = (sku) => {
		if (sku.length > 10) {
			return sku.substring(0, 10) + "...";
		}
		return sku;
	};

	const handleScrollToTop = () => {
		const element = document.getElementById("topHeader");
		element.scrollIntoView({
			behavior: "smooth",
			block: "end",
			inline: "nearest",
		});
	};

	return (
		<React.Fragment>
			<Wrapper>
				<div className="allprod_section jwlsrch__section app__preview choose__setting">
					<div className="row">
						<div className="col-md-12">
							<div className="prodetail__section">
								<div className="col-md-12">
									<div className="prod__maindiv app__preview p-0 border-0">
										<div className="row">
											{dataLoading ? (
												<Spin spinning={dataLoading} />
											) : (
												<>
													<div className="col-lg-6 col-sm-6 col-xs-12">
														<div className="row">
															<div className="col-lg-12">
																<div className="thimage__slider">
																	<ImageGallery
																		items={
																			mountDetails.image
																				? mountDetails.image
																				: []
																		}
																		showFullscreenButton={false}
																		// items={images}
																		//thumbnailPosition={'top'}
																		renderLeftNav={renderLeftNav}
																		renderRightNav={renderRightNav}
																	/>
																	<div
																		className="video__icon"
																		onClick={showModalFullscreen}>
																		<i
																			class="fa fa-arrows-alt"
																			aria-hidden="true"></i>
																	</div>

																	<div className="share__block">
																		{/* <div className="share__button">
                                    <img
                                      src={shareBlue}
                                      onClick={() =>
                                        setShowSocialIcons(!showSocialIcons)
                                      }
                                    />
                                  </div> */}
																		{myOptionsData.showFacebookShare == false &&
																		myOptionsData.showPinterestShare == false &&
																		myOptionsData.showTwitterShare == false &&
																		myOptionsData.showInstagramShare == false &&
																		myOptionsData.showFacebookLike == false ? (
																			<>
																				<div></div>
																			</>
																		) : (
																			<>
																				<div className="share__button">
																					<img
																						src={shareBlue}
																						onClick={() =>
																							setShowSocialIcons(
																								!showSocialIcons
																							)
																						}
																					/>
																				</div>
																			</>
																		)}
																		<div
																			className={
																				showSocialIcons == true
																					? "social__button display-flex"
																					: "social__button display-none"
																			}>
																			<img
																				className={
																					myOptionsData.showFacebookShare ==
																					true
																						? "display-block"
																						: "display-none"
																				}
																				src={shareFacebook}
																				onClick={() =>
																					handleOpenSocialData(
																						socialData.facebookLink
																					)
																				}
																			/>

																			<img
																				className={
																					myOptionsData.showPinterestShare ==
																					true
																						? "display-block"
																						: "display-none"
																				}
																				src={sharePintrest}
																				onClick={() =>
																					handleOpenSocialData(
																						socialData.pintrestLink
																					)
																				}
																			/>

																			<img
																				className={
																					myOptionsData.showTwitterShare == true
																						? "display-block"
																						: "display-none"
																				}
																				src={shareTwitter}
																				onClick={() =>
																					handleOpenSocialData(
																						socialData.twitterLink
																					)
																				}
																			/>
																			<img
																				src={InstaIcon}
																				className={
																					myOptionsData.ShowInstagramPlus ==
																					true
																						? "display-block"
																						: "display-none"
																				}
																				onClick={() =>
																					handleOpenSocialData(
																						socialData.instragramLink
																					)
																				}
																			/>
																			{/* <img
                                        className={
                                          myOptionsData.showFacebookLike == true
                                            ? "display-block"
                                            : "display-none"
                                        }
                                        src={FaceBookLike}
                                        onClick={() =>
                                          handleOpenSocialData(
                                            socialData.facebookLikeLink
                                          )
                                        }
                                      // className="display-block"
                                      /> */}
																		</div>
																	</div>
																</div>
															</div>

															<div className="col-md-12">
																<div className="price__block text-center">
																	<h4 className="subheading">
																		{myOptionsData.showCallForPrice == true ? (
																			<>{t("Call For Price")}</>
																		) : (
																			<>
																				{parseInt(mountDetails.mountingValue) +
																					parseInt(mountDetails.diamondValue) ==
																				0 ? (
																					t("Call For Price")
																				) : (
																					<span className="subheading">
																						{currencySymbol}
																						{(
																							parseInt(
																								mountDetails.mountingValue
																							) +
																							parseInt(
																								mountDetails.diamondValue
																							)
																						)
																							.toFixed()
																							.replace(
																								/\B(?=(\d{3})+(?!\d))/g,
																								","
																							)}
																					</span>
																				)}
																			</>
																		)}
																	</h4>
																	{myOptionsData.showAddToCartButton == true ? (
																		<>
																			<Button
																				onClick={() =>
																					handleAddtoCart(
																						mountDetails.addToCart
																					)
																				}
																				className={
																					parseInt(mountDetails.mountingValue) +
																						parseInt(
																							mountDetails.diamondValue
																						) ==
																					0
																						? "display-none"
																						: "primary-btn"
																				}>
																				{t("Add To Cart")}
																			</Button>
																		</>
																	) : null}
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-6 col-sm-6 col-xs-12">
														<div className="prodesc__div">
															<Tabs
																defaultActiveKey="home"
																id="uncontrolled-tab-example"
																className="tab__div border-0">
																<Tab eventKey="home" title={t("Items Details")}>
																	<div className="tab__contentdiv border__block p-0">
																		<div className="head__div">
																			<h4>{t("Your Diamond Pair")}</h4>
																		</div>
																		<div className="desc__maindiv">
																			<div className="row top__content m-0">
																				<div className="col-md-12 mt-3">
																					<div className="row">
																						<div className="col-md-6">
																							<p className="font__bold">
																								{mountDetails.diamondHeader
																									? mountDetails.diamondHeader
																									: ""}{" "}
																								{t("Carat ROUND Cut Diamond")}
																							</p>
																						</div>
																						<div className="col-md-6 text-right">
																							<span className="mr-2">
																								{t("SKU#")}{" "}
																								<span
																									className="sku"
																									title={
																										mountDetails.diamondVendorSku ||
																										""
																									}>
																									{truncateSku(
																										mountDetails.diamondVendorSku ||
																											""
																									)}
																								</span>
																							</span>
																							<span className="mr-2">
																								<br />
																								{t("SKU#")}{" "}
																								<span
																									className="sku"
																									title={
																										mountDetails.diamondVendorSku1 ||
																										""
																									}>
																									{truncateSku(
																										mountDetails.diamondVendorSku1 ||
																											""
																									)}
																								</span>
																							</span>
																						</div>
																					</div>

																					<p>
																						{mountDetails.diamondDiscription
																							? mountDetails.diamondDiscription
																							: ""}
																					</p>
																				</div>
																				<div className="col-md-12">
																					<div className="detailblk mb-3">
																						<span className="mr-2 font__bold">
																							{t("Diamond Pair")}:{" "}
																						</span>
																						<span className="font__bold">
																							{myOptionsData.showCallForPrice ==
																							true ? (
																								<>{t("Call For Price")}</>
																							) : (
																								<>
																									{mountDetails.diamondValue ==
																									"0" ? (
																										" Call For Price"
																									) : (
																										<span className="subheading">
																											{currencySymbol}
																											{parseInt(
																												mountDetails.diamondValue
																											)
																												.toFixed()
																												.replace(
																													/\B(?=(\d{3})+(?!\d))/g,
																													","
																												)}
																										</span>
																									)}
																								</>
																							)}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className="head__div">
																			<h4>{t("Your Mounting")}</h4>
																		</div>
																		<div className="desc__maindiv">
																			<div className="row top__content m-0 flex-column">
																				<div className="col-md-12 mt-3">
																					<div className="row">
																						<div className="col-md-6">
																							<p className="font__bold">
																								{mountDetails.mountingHeader
																									? mountDetails.mountingHeader
																									: ""}
																							</p>
																						</div>
																						<div className="col-md-6 text-right">
																							<span className="mr-2">
																								{t("Style#")}{" "}
																								<span
																									style={{
																										color: "#24abe2",
																										cursor: "pointer",
																									}}>
																									{mountDetails.mountingStyle
																										? mountDetails.mountingStyle
																										: ""}
																									{completeEarringInputData.backingType
																										? "-"
																										: ""}
																									{
																										completeEarringInputData.backingType
																									}
																								</span>
																							</span>
																						</div>
																					</div>
																					<p>
																						{mountDetails.mountingDescription
																							? mountDetails.mountingDescription
																							: ""}
																					</p>
																				</div>

																				<div className="col-md-6">
																					<div className="input__block">
																						<label>{t("Metal Type")}</label>
																						<Select
																							className="border__grey"
																							value={
																								completeEarringInputData.metalType
																									? completeEarringInputData.metalType
																									: []
																							}
																							placeholder={t(
																								"Select Metal Type"
																							)}
																							optionFilterProp="children"
																							onChange={(e) =>
																								handleCompleteEarringInputChange(
																									e,
																									"metalType"
																								)
																							}>
																							<Option value="">
																								{t("Select Metal Type")}
																							</Option>
																							{studMetalTypeList &&
																								studMetalTypeList.map(
																									(item, i) => {
																										return (
																											<Option
																												value={item.value}>
																												{item.text}
																											</Option>
																										);
																									}
																								)}
																						</Select>
																					</div>
																				</div>

																				<div className="col-md-6">
																					<div className="input__block">
																						<label>{t("Metal Color")}</label>
																						<Select
																							className="border__grey"
																							value={
																								completeEarringInputData.metalColor
																									? completeEarringInputData.metalColor
																									: []
																							}
																							name="metalColor"
																							placeholder={t(
																								"Select Metal Color"
																							)}
																							optionFilterProp="children"
																							onChange={(e) =>
																								handleCompleteEarringInputChange(
																									e,
																									"metalColor"
																								)
																							}>
																							<Option value="">
																								{t("Select Metal Color")}
																							</Option>
																							{studMetalColorList &&
																								studMetalColorList.map(
																									(item, i) => {
																										return (
																											<Option
																												value={item.value}>
																												{item.text}
																											</Option>
																										);
																									}
																								)}
																						</Select>
																					</div>
																				</div>

																				<div className="col-md-6">
																					<div className="input__block">
																						<label>{t("Backing Type")}</label>
																						<Select
																							className="border__grey"
																							value={
																								completeEarringInputData.backingType
																									? completeEarringInputData.backingType
																									: []
																							}
																							name="backingType"
																							placeholder={t(
																								"Select Backing Type"
																							)}
																							optionFilterProp="children"
																							onChange={(e) =>
																								handleCompleteEarringInputChange(
																									e,
																									"backingType"
																								)
																							}>
																							<Option value="">
																								{t("Select Backing Type")}
																							</Option>
																							{studBackingTypeList &&
																								studBackingTypeList.map(
																									(item, i) => {
																										return (
																											<Option
																												value={item.value}>
																												{item.text}
																											</Option>
																										);
																									}
																								)}
																						</Select>
																					</div>
																				</div>

																				<div className="col-md-12">
																					<div className="detailblk mb-3">
																						<span className="mr-2 font__bold">
																							{t("Mounting")}:{" "}
																						</span>
																						<span className="subheading">
																							{myOptionsData.showCallForPrice ==
																							true ? (
																								<>{t("Call For Price")}</>
																							) : (
																								<>
																									{mountDetails.mountingValue ==
																									"0" ? (
																										" Call For Price"
																									) : (
																										<span className="subheading">
																											{currencySymbol}
																											{parseInt(
																												mountDetails.mountingValue
																											)
																												.toFixed()
																												.replace(
																													/\B(?=(\d{3})+(?!\d))/g,
																													","
																												)}
																										</span>
																									)}
																								</>
																							)}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className="desc__maindiv">
																			<div className="row top__content m-0">
																				<div className="row itemdetails__btns p-0">
																					<div className="col-sm-12 col-md-6">
																						<p onClick={showDropHintModal}>
																							<span className="img__box">
																								<img src={drophint} />
																							</span>
																							<span>{t("Drop A Hint")}</span>
																						</p>
																					</div>

																					<div className="col-sm-12 col-md-6">
																						<p
																							onClick={
																								showRequestMoreInfoModal
																							}>
																							<span className="img__box">
																								<img src={infoblack} />
																							</span>
																							<span>
																								{t("Request More Info")}
																							</span>
																						</p>
																					</div>
																				</div>

																				<div className="row itemdetails__btns p-0">
																					<div className="col-sm-12 col-md-6">
																						<p onClick={showEmailFriendModal}>
																							<span className="img__box">
																								<img src={emailicon} />
																							</span>
																							<span>
																								{t("E-Mail A Friend")}
																							</span>
																						</p>
																					</div>
																					{myOptionsData.showScheduleViewing ==
																						true && (
																						<div className="col-sm-12 col-md-6">
																							<p
																								onClick={
																									showScheduleViewingModal
																								}>
																								<span className="img__box">
																									<img src={calender} />
																								</span>
																								<span>
																									{t("Schedule Viewing")}
																								</span>
																							</p>
																						</div>
																					)}
																				</div>

																				<div className="row itemdetails__btns p-0">
																					<div className="col-sm-12 col-md-6">
																						<p
																							onClick={() => {
																								handlePrintDiamondList();
																							}}>
																							<span className="img__box">
																								<img src={IconPrint} />
																							</span>
																							<span>{t("Print Details")}</span>
																						</p>
																					</div>
																				</div>

																				<div className="col-md-12">
																					<p>
																						{t(
																							"Have a question regarding this item? Our specialists are available to assist you."
																						)}
																					</p>
																				</div>

																				<div className="col-md-12">
																					<div className="dtl__socialsection">
																						{myOptionsData.showFacebookShare ==
																							true && (
																							<button
																								onClick={() =>
																									handleOpenSocialData(
																										socialData.facebookLink
																									)
																								}
																								className="dtl__socialbtn fb__btn">
																								<img src={DtlFacebook} />
																							</button>
																						)}

																						{myOptionsData.showPinterestShare ==
																							true && (
																							<button
																								onClick={() =>
																									handleOpenSocialData(
																										socialData.pintrestLink
																									)
																								}
																								className="dtl__socialbtn pintrest">
																								<img src={DtlPintrest} />
																							</button>
																						)}

																						{myOptionsData.showTwitterShare ==
																							true && (
																							<button
																								onClick={() =>
																									handleOpenSocialData(
																										socialData.twitterLink
																									)
																								}
																								className="dtl__socialbtn tweeter">
																								<img src={DtlTwitter} />
																							</button>
																						)}

																						{/* {myOptionsData.ShowInstagramPlus ==
                                              true && (
                                                <button
                                                  onClick={() =>
                                                    handleOpenSocialData(
                                                      socialData.instragramLink
                                                    )
                                                  }
                                                  className="dtl__socialbtn instagram"
                                                >
                                                  <img src={DtlInstagram} />
                                                </button>
                                              )} */}

																						{/* {myOptionsData.showFacebookLike ==
                                              true && (
                                                <button
                                                  onClick={() =>
                                                    handleOpenSocialData(
                                                      socialData.facebookLikeLink
                                                    )
                                                  }
                                                  className="dtl__socialbtn googlePlus"
                                                >
                                                  <img
                                                    src={FaceBookLike}
                                                    className="fbLike"
                                                  />
                                                </button>
                                              )} */}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</Tab>
															</Tabs>
														</div>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* print Details start */}
				<React.Fragment>
					<div id="printsection" style={{ padding: "20px", display: "none" }}>
						<div className="allprod_section jwlsrch__section app__preview choose__setting">
							<div className="row">
								<div className="col-md-12">
									<div className="prodetail__section">
										<div className="col-md-12">
											<div className="prod__maindiv app__preview p-0 border-0">
												<div className="row">
													<div className="col-lg-6">
														<div className="row">
															<div className="col-lg-12">
																<div className="thimage__slider">
																	<ImageGallery
																		items={images}
																		//thumbnailPosition={'top'}
																		showFullscreenButton={false}
																		renderLeftNav={renderLeftNav}
																		renderRightNav={renderRightNav}
																	/>
																	<div className="share__block">
																		<div className="share__button">
																			<img src={shareBlue} />
																		</div>
																		<div className="social__button">
																			<img src={shareFacebook} />
																			<img src={sharePintrest} />
																			<img src={shareTwitter} />
																		</div>
																	</div>
																</div>
															</div>

															<div className="col-md-12">
																<div className="price__block text-center">
																	<h4 className="subheading">
																		{currencySymbol}
																		{mountDetails.mountingValue
																			? mountDetails.mountingValue
																			: 0 + mountDetails.diamondValue
																			? mountDetails.diamondValue
																			: 0}
																	</h4>
																	<Button
																		className="primary-btn"
																		onClick={() =>
																			handleAddtoCart(mountDetails.addToCart)
																		}>
																		Add To Cart
																	</Button>
																</div>
															</div>
														</div>
													</div>
													<div className="col-lg-6 col-md-12">
														<div className="prodesc__div">
															<Tabs
																defaultActiveKey="home"
																id="uncontrolled-tab-example"
																className="tab__div border-0">
																<Tab eventKey="home" title={t("Items Details")}>
																	<div className="tab__contentdiv border__block p-0">
																		<div className="head__div">
																			<h4>{t("Your Diamond Pair")}</h4>
																		</div>
																		<div className="desc__maindiv">
																			<div className="row top__content m-0">
																				<div className="col-md-12 mt-3">
																					<div className="row">
																						<div className="col-md-6">
																							<p className="font__bold">
																								{mountDetails.diamondHeader
																									? mountDetails.diamondHeader
																									: ""}{" "}
																								{t("Carat ROUND Cut Diamond")}
																							</p>
																						</div>
																						<div className="col-md-6 text-right">
																							<span className="mr-2">
																								{t("SKU#")}{" "}
																								<span
																									className="sku"
																									title={
																										mountDetails.diamondVendorSku ||
																										""
																									}>
																									{truncateSku(
																										mountDetails.diamondVendorSku ||
																											""
																									)}
																								</span>
																							</span>
																							<span className="mr-2">
																								<br />
																								{t("SKU#")}{" "}
																								<span
																									className="sku"
																									title={
																										mountDetails.diamondVendorSku1 ||
																										""
																									}>
																									{truncateSku(
																										mountDetails.diamondVendorSku1 ||
																											""
																									)}
																								</span>
																							</span>
																						</div>
																					</div>

																					<p>
																						{mountDetails.diamondDiscription
																							? mountDetails.diamondDiscription
																							: ""}
																					</p>
																				</div>
																				<div className="col-md-12">
																					<div className="detailblk mb-3">
																						<span className="mr-2 font__bold">
																							{t("Diamond Pair")}:{" "}
																						</span>
																						<span className="subheading">
																							{myOptionsData.showCallForPrice ==
																							true ? (
																								<>{t("Call For Price")}</>
																							) : (
																								<>
																									{mountDetails.diamondValue ==
																									"0" ? (
																										" Call For Price"
																									) : (
																										<span className="subheading">
																											{currencySymbol}
																											{parseInt(
																												mountDetails.diamondValue
																											)
																												.toFixed()
																												.replace(
																													/\B(?=(\d{3})+(?!\d))/g,
																													","
																												)}
																										</span>
																									)}
																								</>
																							)}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className="head__div">
																			<h4>{t("Your Mounting")}</h4>
																		</div>
																		<div className="desc__maindiv">
																			<div className="row top__content m-0">
																				<div className="col-md-12 mt-3">
																					<div className="row">
																						<div className="col-md-6">
																							<p className="font__bold">
																								{mountDetails.mountingHeader
																									? mountDetails.mountingHeader
																									: ""}
																							</p>
																						</div>
																						<div className="col-md-6 text-right">
																							<span className="mr-2">
																								{t("Style#")}
																								{mountDetails.mountingStyle
																									? mountDetails.mountingStyle
																									: ""}
																							</span>
																						</div>
																					</div>
																					<p>
																						{mountDetails.mountingDescription
																							? mountDetails.mountingDescription
																							: ""}
																					</p>
																				</div>

																				<div className="col-md-3">
																					<div className="input__block">
																						<label>{t("Metal Type")}</label>
																						<Select
																							className="border__grey"
																							value={
																								completeEarringInputData.metalType
																									? completeEarringInputData.metalType
																									: []
																							}
																							placeholder={t(
																								"Select Metal Type"
																							)}
																							optionFilterProp="children"
																							onChange={(e) =>
																								handleCompleteEarringInputChange(
																									e,
																									"metalType"
																								)
																							}>
																							<Option value="">
																								{t("Select Metal Type")}
																							</Option>
																							{studMetalTypeList &&
																								studMetalTypeList.map(
																									(item, i) => {
																										return (
																											<Option
																												value={item.value}>
																												{item.text}
																											</Option>
																										);
																									}
																								)}
																						</Select>
																					</div>
																				</div>

																				<div className="col-md-3">
																					<div className="input__block">
																						<label>{t("Metal Color")}</label>
																						<Select
																							className="border__grey"
																							value={
																								completeEarringInputData.metalColor
																									? completeEarringInputData.metalColor
																									: []
																							}
																							name="metalColor"
																							placeholder={t(
																								"Select Metal Color"
																							)}
																							optionFilterProp="children"
																							onChange={(e) =>
																								handleCompleteEarringInputChange(
																									e,
																									"metalColor"
																								)
																							}>
																							<Option value="">
																								{t("Select Metal Color")}
																							</Option>
																							{studMetalColorList &&
																								studMetalColorList.map(
																									(item, i) => {
																										return (
																											<Option
																												value={item.value}>
																												{item.text}
																											</Option>
																										);
																									}
																								)}
																						</Select>
																					</div>
																				</div>

																				<div className="col-md-3">
																					<div className="input__block">
																						<label>{t("Backing Type")}</label>
																						<Select
																							className="border__grey"
																							value={
																								completeEarringInputData.backingType
																									? completeEarringInputData.backingType
																									: []
																							}
																							name="backingType"
																							placeholder={t(
																								"Select Backing Type"
																							)}
																							optionFilterProp="children"
																							onChange={(e) =>
																								handleCompleteEarringInputChange(
																									e,
																									"backingType"
																								)
																							}>
																							<Option value="">
																								{t("Select Backing Type")}
																							</Option>
																							{studBackingTypeList &&
																								studBackingTypeList.map(
																									(item, i) => {
																										return (
																											<Option
																												value={item.value}>
																												{item.text}
																											</Option>
																										);
																									}
																								)}
																						</Select>
																					</div>
																				</div>

																				<div className="col-md-12">
																					<div className="detailblk mb-3">
																						<span className="mr-2 font__bold">
																							{t("Mounting")}:{" "}
																						</span>
																						<span className="subheading">
																							{myOptionsData.showCallForPrice ==
																							true ? (
																								<>{t("Call For Price")}</>
																							) : (
																								<>
																									{mountDetails.mountingValue ==
																									"0" ? (
																										t("Call For Price")
																									) : (
																										<span className="subheading">
																											{currencySymbol}
																											{parseInt(
																												mountDetails.mountingValue
																											)
																												.toFixed()
																												.replace(
																													/\B(?=(\d{3})+(?!\d))/g,
																													","
																												)}
																										</span>
																									)}
																								</>
																							)}
																						</span>
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className="desc__maindiv">
																			<div className="row top__content m-0">
																				<div className="itemdetails__btns">
																					<p onClick={showDropHintModal}>
																						<img src={drophint} />
																						<span>{t("Drop A Hint")}</span>
																					</p>
																					<p onClick={showRequestMoreInfoModal}>
																						<img src={infoblack} />
																						<span>
																							{t("Request More Info")}
																						</span>
																					</p>
																					<p onClick={showEmailFriendModal}>
																						<img src={emailicon} />
																						<span>{t("E-Mail A Friend")}</span>
																					</p>
																					{myOptionsData.showScheduleViewing ==
																						true && (
																						<p
																							onClick={
																								showScheduleViewingModal
																							}>
																							<img src={calender} />
																							<span>
																								{t("Schedule Viewing")}
																							</span>
																						</p>
																					)}
																					<p
																						onClick={() => {
																							handlePrintDiamondList();
																						}}>
																						<img src={IconPrint} />
																						<span>{t("Print Details")}</span>
																					</p>
																				</div>

																				<div className="col-md-12">
																					<p>
																						{t(
																							"Have a question regarding this item? Our specialists are available to assist you."
																						)}
																					</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</Tab>
															</Tabs>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</React.Fragment>
			</Wrapper>
			{/* Modal Section Starts */}

			{/* Request More Info Modal Starts */}
			<Modal
				title={t("Request More Information")}
				visible={requestMoreModal}
				style={{ top: 20 }}
				width={700}
				className="request_info"
				maskClosable={false}
				onCancel={closeRequestMoreModal}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={closeRequestMoreModal}>
						{t("Cancel")}
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						loading={buttonLoading}
						onClick={handleSubmitRequestMoreInfo}>
						{t("Request")}
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<p>{t("Our specialist will contact you.")}</p>
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your Name")} <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											value={requestMoreInfoInputData.name}
											placeholder={t("Enter Your Name")}
											className={
												requestMoreInfoInputDataValidation.nameval &&
												"border__red"
											}
											onChange={(e) =>
												handleRequestMoreInfoChange(e, "name", "input")
											}
										/>
										{requestMoreInfoInputDataValidation.nameval && (
											<p className="error-color-red">
												{requestMoreInfoInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your E-Mail Address")}{" "}
											<span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Email")}
											value={requestMoreInfoInputData.email}
											className={
												requestMoreInfoInputDataValidation.emailval &&
												"border__red"
											}
											onChange={(e) =>
												handleRequestMoreInfoChange(e, "email", "input")
											}
										/>
										{requestMoreInfoInputDataValidation.emailval && (
											<p className="error-color-red">
												{requestMoreInfoInputDataValidation.emailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your Phone Number")}{" "}
											<span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Phone Number")}
											value={requestMoreInfoInputData.phoneNumber}
											className={
												requestMoreInfoInputDataValidation.phoneNumberval &&
												"border__red"
											}
											onChange={(e) =>
												handleRequestMoreInfoChange(e, "phoneNumber", "input")
											}
										/>
										{requestMoreInfoInputDataValidation.phoneNumberval && (
											<p className="error-color-red">
												{requestMoreInfoInputDataValidation.phoneNumberval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>{t("Contact Preference")}</label>
										<div className="row">
											<div className="radio__block col-6">
												<input
													type="radio"
													value="byEmail"
													checked={
														requestMoreInfoInputData.contactPreference ==
														"byEmail"
															? true
															: false
													}
													onChange={(e) =>
														handleRequestMoreInfoChange(
															e,
															"contactPreference",
															"radio"
														)
													}
												/>

												<label>{t("By Email")}</label>
											</div>
											<div className="radio__block col-6">
												<input
													type="radio"
													value="byPhone"
													checked={
														requestMoreInfoInputData.contactPreference ==
														"byPhone"
															? true
															: false
													}
													onChange={(e) =>
														handleRequestMoreInfoChange(
															e,
															"contactPreference",
															"radio"
														)
													}
												/>

												<label>{t("By Phone")}</label>
											</div>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="input__block">
										<label>{t("Write A Personal Message ...")}</label>
										<textarea
											value={requestMoreInfoInputData.personalMessage}
											placeholder={t("Write A Personal Message ...")}
											onChange={(e) =>
												handleRequestMoreInfoChange(
													e,
													"personalMessage",
													"radio"
												)
											}></textarea>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* Request More Info Modal Ends */}

			{/* Drop A Hint Modal Starts */}
			<Modal
				title={t("Drop A Hint")}
				visible={showDropModal}
				style={{ top: 20 }}
				width={700}
				className="drop_a_hint"
				maskClosable={false}
				onCancel={hideDropHintModal}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={hideDropHintModal}>
						{t("Cancel")}
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						loading={buttonLoading}
						onClick={handleSubmitDropHint}>
						{t("Drop Hint")}
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<p>{t("Because you deserve this.")}</p>
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your Name")} <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Name")}
											value={dropHintInputData.name}
											className={
												dropHintInputDataValidation.nameval && "border__red"
											}
											onChange={(e) => handleDropHintChange(e, "name", "input")}
										/>
										{dropHintInputDataValidation.nameval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your E-Mail")} <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Email")}
											value={dropHintInputData.email}
											className={
												dropHintInputDataValidation.emailval && "border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "email", "input")
											}
										/>
										{dropHintInputDataValidation.emailval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.emailval}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Hint Recipient's Name")}{" "}
											<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Hint Recipient's Name")}
											value={dropHintInputData.recipientName}
											className={
												dropHintInputDataValidation.recipientNameval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "recipientName", "input")
											}
										/>
										{dropHintInputDataValidation.recipientNameval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.recipientNameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Hint Recipient's E-Mail")}{" "}
											<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Hint Recipient's E-Mail")}
											value={dropHintInputData.recipientEmail}
											className={
												dropHintInputDataValidation.recipientEmailval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "recipientEmail", "input")
											}
										/>
										{dropHintInputDataValidation.recipientEmailval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.recipientEmailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Reason For This Gift")}{" "}
											<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Reason For This Gift")}
											value={dropHintInputData.giftReason}
											className={
												dropHintInputDataValidation.giftReasonval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "giftReason", "input")
											}
										/>
										{dropHintInputDataValidation.giftReasonval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.giftReasonval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Gift Deadline")} <span className="mandatory">*</span>
										</label>
										<input
											type="date"
											value={dropHintInputData.giftDeadline}
											className={
												dropHintInputDataValidation.giftDeadlineval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "giftDeadline", "date")
											}
											min={disablePastDate()}
										/>
										{dropHintInputDataValidation.giftDeadlineval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.giftDeadlineval}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-12">
									<div className="input__block">
										<label>
											{t("Add A Personal Message Here")} ...{" "}
											<span className="mandatory">*</span>
										</label>
										<textarea
											value={dropHintInputData.personalMessage}
											placeholder={t("Add A Personal Message Here ...")}
											className={
												dropHintInputDataValidation.personalMessageval &&
												"border__red"
											}
											onChange={(e) =>
												handleDropHintChange(e, "personalMessage", "input")
											}></textarea>
										{dropHintInputDataValidation.personalMessageval && (
											<p className="error-color-red">
												{dropHintInputDataValidation.personalMessageval}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* Drop A Hint Modal Ends */}

			{/* E-Mail A Friend Modal Starts */}
			<Modal
				title={t("E-Mail A Friend")}
				visible={emailFriendModal}
				style={{ top: 20 }}
				width={700}
				className="email_a_friend"
				maskClosable={false}
				onCancel={closeEmailFriendModal}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={closeEmailFriendModal}>
						{t("Cancel")}
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						loading={buttonLoading}
						onClick={handleSubmitEmailFriend}>
						{t("Send To Friend")}
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your Name")} <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Name")}
											value={emailFriendInputData.name}
											className={
												emailFriendInputDataValidation.nameval && "border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "name", "input")
											}
										/>
										{emailFriendInputDataValidation.nameval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your E-Mail")} <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Email")}
											value={emailFriendInputData.email}
											className={
												emailFriendInputDataValidation.emailval && "border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "email", "input")
											}
										/>
										{emailFriendInputDataValidation.emailval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.emailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your Friend's Name")}{" "}
											<span className="mandatory ml-1"> *</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Friend's Name")}
											value={emailFriendInputData.friendName}
											className={
												emailFriendInputDataValidation.friendNameval &&
												"border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "friendName", "input")
											}
										/>
										{emailFriendInputDataValidation.friendNameval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.friendNameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your Friend's E-Mail")}{" "}
											<span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Friend's E-Mail")}
											value={emailFriendInputData.friendEmail}
											className={
												emailFriendInputDataValidation.friendEmailval &&
												"border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "friendEmail", "input")
											}
										/>
										{emailFriendInputDataValidation.friendEmailval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.friendEmailval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="input__block">
										<label>
											{t("Add A Personal Message Here")} ...{" "}
											<span className="mandatory ml-1"> *</span>
										</label>
										<textarea
											value={emailFriendInputData.personalMessage}
											placeholder={t("Add A Personal Message Here ...")}
											className={
												emailFriendInputDataValidation.personalMessageval &&
												"border__red"
											}
											onChange={(e) =>
												handleEmailFriendChange(e, "personalMessage", "input")
											}></textarea>
										{emailFriendInputDataValidation.personalMessageval && (
											<p className="error-color-red">
												{emailFriendInputDataValidation.personalMessageval}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* E-Mail A Friend Modal Ends */}

			{/* Print Details Modal Starts */}

			{/* Print Details Modal Ends */}

			{/* Schedule Viewing Modal Starts */}
			<Modal
				title={t("Schedule A Viewing")}
				style={{ top: 20 }}
				width={700}
				visible={scheduleViewing}
				className="schedule_viewing"
				onCancel={closeScheduleViewingModal}
				maskClosable={false}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={closeScheduleViewingModal}>
						{t("Cancel")}
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						loading={buttonLoading}
						onClick={handleSubmitSheduleViewing}>
						{t("Schedule")}
					</Button>,
				]}>
				<div className="row">
					<div class="col-md-12">
						<p>{t("See this item and more in our store")}</p>
						<div className="table__block">
							<div className="row p-0 border-0 form__fields mh-auto">
								<div className="col-md-6">
									<div className="input__block">
										<label>
											{t("Your Name")} <span className="mandatory">*</span>
										</label>
										<input
											type="text"
											placeholder={t("Enter Your Name")}
											value={scheduleViewingInputData.name}
											className={
												scheduleViewingInputDataValidation.nameval &&
												"border__red"
											}
											onChange={(e) =>
												handleScheduleViewingChange(e, "name", "input")
											}
										/>
										{scheduleViewingInputDataValidation.nameval && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.nameval}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>{t("Your Email")}</label>
										<input
											type="text"
											placeholder={t("Enter Your Email")}
											value={scheduleViewingInputData.email}
											onChange={(e) =>
												handleScheduleViewingChange(e, "email", "input")
											}
											className={
												scheduleViewingInputDataValidation.emailVal &&
												"border__red"
											}
										/>
										{scheduleViewingInputDataValidation.emailVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.emailVal}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-6">
									<div className="input__block">
										<label>{t("Your Phone Number")}</label>
										<input
											type="text"
											placeholder={t("Enter Your Phone Number")}
											value={scheduleViewingInputData.phoneNumber}
											onChange={(e) =>
												handleScheduleViewingChange(e, "phoneNumber", "input")
											}
											className={
												scheduleViewingInputDataValidation.phoneNumberVal &&
												"border__red"
											}
										/>
										{scheduleViewingInputDataValidation.phoneNumberVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.phoneNumberVal}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>{t("Location")}</label>
										<Select
											name="location"
											optionFilterProp="children"
											value={scheduleViewingInputData.location}
											onChange={(e) =>
												handleScheduleViewingChange(e, "location", "select")
											}
											className={
												scheduleViewingInputDataValidation.locationVal
													? "border__red"
													: "border__grey"
											}>
											<Option value="">{t("Select Location")}</Option>
											{locationDetails &&
												locationDetails.map((item, i) => {
													return (
														<Option value={item.LocationId}>
															{item.LocationName}
														</Option>
													);
												})}
										</Select>
										{scheduleViewingInputDataValidation.locationVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.locationVal}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>{t("When are you available?")}</label>
										<input
											type="date"
											value={scheduleViewingInputData.appointmentDate}
											onChange={(e) =>
												handleScheduleViewingChange(e, "appointmentDate")
											}
											min={disablePastDate()}
											className={
												scheduleViewingInputDataValidation.appointmentDateVal &&
												"border__red"
											}
										/>
										{scheduleViewingInputDataValidation.appointmentDateVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.appointmentDateVal}
											</p>
										)}
									</div>
								</div>

								<div className="col-md-6">
									<div className="input__block">
										<label>{t("Appointment Time")}</label>
										<Select
											name="appointmentTime"
											optionFilterProp="children"
											placeholder={t("Select Appointment Time")}
											value={scheduleViewingInputData.appointmentTime}
											onChange={(e) =>
												handleScheduleViewingChange(
													e,
													"appointmentTime",
													"select"
												)
											}
											className={
												scheduleViewingInputDataValidation.appointmentTimeVal
													? "border__red"
													: "border__grey"
											}>
											<Option value="">{t("Appointment Time")}</Option>
											<Option value="8:00 AM">{t("8 AM")}</Option>
											<Option value="8:30 AM">{t("8:30 AM")}</Option>
											<Option value="9:00 AM">{t("9 AM")}</Option>
											<Option value="9:30 AM">{t("9:30 AM")}</Option>
											<Option value="10:00 AM">{t("10 AM")}</Option>
											<Option value="10:30 AM">{t("10:30 AM")}</Option>
											<Option value="11:00 AM">{t("11 AM")}</Option>
											<Option value="11:30 AM">{t("11:30 AM")}</Option>
											<Option value="12:00 AM">{t("12 AM")}</Option>
											<Option value="12:00 PM">{t("12:30 PM")}</Option>
											<Option value="1:00 PM">{t("1 PM")}</Option>
											<Option value="1:30 PM">{t("1:30 PM")}</Option>
											<Option value="2:00 PM">{t("2 PM")}</Option>
											<Option value="2:30 PM">{t("2:30 PM")}</Option>
											<Option value="3:00 PM">{t("3 PM")}</Option>
											<Option value="3:30 PM">{t("3:30 PM")}</Option>
											<Option value="4:00 PM">{t("4 PM")}</Option>
											<Option value="4:30 PM">{t("4:30 PM")}</Option>
											<Option value="5:00 PM">{t("5 PM")}</Option>
											<Option value="5:30 PM">{t("5:30 PM")}</Option>
											<Option value="6:00 PM">{t("6 PM")}</Option>
											<Option value="6:30 PM">{t("6:30 PM")}</Option>
											<Option value="7:00 PM">{t("7 PM")}</Option>
											<Option value="7:30 PM">{t("7:30 PM")}</Option>
											<Option value="8:00 PM">{t("8 PM")}</Option>
											<Option value="8:30 PM">{t("8:30 PM")}</Option>
											<Option value="9:00 PM">{t("9 PM")}</Option>
											<Option value="9:30 PM">{t("9:30 PM")}</Option>
											<Option value="10:00 PM">{t("10 PM")}</Option>
											<Option value="10:30 PM">{t("10:30 PM")}</Option>
										</Select>
										{scheduleViewingInputDataValidation.appointmentTimeVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.appointmentTimeVal}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<div className="input__block">
										<label>{t("Add A Message Here")} ...</label>
										<textarea
											value={scheduleViewingInputData.message}
											placeholder={t("Add A Message Here ...")}
											onChange={(e) =>
												handleScheduleViewingChange(e, "message", "input")
											}
											className={
												scheduleViewingInputDataValidation.messageVal &&
												"border__red"
											}></textarea>
										{scheduleViewingInputDataValidation.messageVal && (
											<p className="error-color-red">
												{scheduleViewingInputDataValidation.messageVal}
											</p>
										)}
									</div>
								</div>
								<div className="col-md-12">
									<p className="font__bold d-flex justify-between mb-0">
										<span className="font__bold">
											{locationData.locationName &&
												Parse(locationData.locationName)}
										</span>
									</p>
									<p className="font__bold mb-0">
										<span className="font__bold">
											{locationData && locationData.phone}
										</span>
									</p>
									<p className="font__bold">
										<a href={`mailto:${locationData && locationData.emailID}`}>
											{locationData && locationData.emailID}
										</a>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal
				title="Please confirm"
				visible={confirmScheduleModal}
				width={700}
				style={{ top: 20 }}
				className="cert_modal"
				onCancel={() => setConfirmScheduleModal(false)}
				footer={[
					<Button
						key="back"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={() => setConfirmScheduleModal(false)}>
						{t("Cancel")}
					</Button>,
					<Button
						key="submit"
						type="primary"
						style={{
							background: `${varColorObj.callToActionColor}`,
							color: "#ffffff",
						}}
						onClick={handleSaveScheduleViewing}
						loading={buttonLoading}>
						{t("Schedule")}
					</Button>,
				]}>
				<p>{t("Are you sure you want to schedule?")}</p>
			</Modal>
			{/* Schedule Viewing Modal Ends */}

			{/* Thumbnail Slider Video Modal Starts */}
			<Modal
				title=""
				style={{ top: 20 }}
				visible={isModalVisible6}
				className="video__modal"
				onCancel={handleCancel6}
				footer={false}>
				<div className="video__section">
					<video
						width="100%"
						height="80%"
						src="https://www.overnightmountings.com/gemfind/library/Images_And_Videos/F1626/F1626.video.white.mp4"
						autoPlay
						loop
						controls></video>
				</div>
			</Modal>
			{/* Thumbnail Slider Video Modal Ends */}

			{/* Fullscreen Modal Starts */}
			<Modal
				title=""
				visible={isModalVisibleFullscreen}
				className="video__modal fullscreen__modal"
				onCancel={handleCancelFullscreen}
				footer={false}
				width={1000}>
				<div className="fullscreen__section">
					<ImageGallery
						items={mountDetails.image ? mountDetails.image : []}
						showFullscreenButton={false}
						renderLeftNav={renderLeftNav}
						renderRightNav={renderRightNav}
					/>
				</div>
			</Modal>
			{/* Fullscreen Modal Ends */}

			{/* Modal Section Ends */}
		</React.Fragment>
	);
};

export default CompleteYourRing;
